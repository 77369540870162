.app {
  width: 100%;
}
.header {
  padding: 5px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}
.hero-books {
  margin-top: 100px;
}
.site-brand {
  text-align: center;
}

.logo {
  width: 35px;
  height: auto;
}
.brand-name {
  font-size: 20px;
}

.brand-slogan {
  font-size: 12px;
  color: #fff;
}

.contact-card {
  color: #fff;
  width: 200px;
  margin: 20px;
}
.contact-card h3 {
  border-bottom: 2px solid #fff;
  text-align: center;
  margin-bottom: 20px;
}

.contact-card-body p {
  font-size: 1em;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}
.contact-card-body p a {
  color: lightgreen;
  text-decoration: none;
}
.contact-card-body p a:hover {
  color: #ffdb00;
  text-decoration: underline;
}

.site-nav {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  padding-bottom: 10px;
}

.footer-menu > li a {
  font-family: "Sohn Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgba(194, 203, 213, 1);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.footer-menu > li a {
  font-family: inherit;
  color: #fff;
  font-size: 14px;
}

.footer-menu > li a:hover {
  color: #50b9e9;
}

.footer-menu > li {
  display: inline-block;
  margin: 0 20px;
  position: relative;
}

.site-menu > li a:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 3px;
  background-color: #50b9e9;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  border-radius: 1em;
}

.site-menu li a:hover:after,
.site-menu li a:focus:after,
.site-menu li a.active:hover:after,
.site-menu li a.active:focus:after,
.site-menu li a.active:after {
  opacity: 1;
}
.site-menu li a.active {
  color: #50b9e9;
}

.outbooks-cards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.outbook-card {
  width: 280px;
  position: relative;
  margin: 20px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
}
.out-image img {
  width: 200px;
  text-align: center;
}
.outbook-card.btreasure {
  background-color: #50b9e9;
}
.outbook-card.bdaddaughter {
  background-color: #ffdb00;
}

.outbook-card-inner {
  padding: 10px;
  background-color: rgba(194, 203, 213, 1);
  position: relative;
  z-index: 10;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}

.bookcard-body {
  margin: 20px 0;
  margin-bottom: 25px;
  font-size: 1em;
  padding: 0 20px;
}
.cta-container {
  text-align: center;
}
.book-cta {
  border-radius: 1px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  padding-bottom: 13px;
  width: 250px;
  font-size: 1em;
  text-transform: uppercase;
}

.book-cta.lyellow {
  background-color: #ffdb00;
  color: #21334d;
}

.book-cta.darkb {
  background-color: #21334d;
  color: #fff;
}
.book-cta.cblue {
  background-color: #50b9e9;
  color: #fff;
}
.wft-img {
  width: 150px;
}
.section-title {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  width: 300px;
  margin: auto;
  font-size: 40px;
}

.section-title:after {
  content: "";
  position: absolute;
  top: 110%;
  height: 8px;
  background-color: #ffdb00;
  width: 100px;
  left: 100px;
  margin: auto;
  opacity: 1;
  transition: 0.3s ease;
  border-radius: 1px;
}
.section-head.width-300 {
  width: 380px;
}

.colored-section-wrap {
  align-items: center;
}
.about-container {
  width: 500px;
  margin: auto;
  text-align: justify;
  padding: 10px;
  border: 2px solid #ffdb00;
  border-radius: 2px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  font-family: Baskerville;
}

.about-container p {
  margin: 10px 0;
  font-size: 1.2em;
}
.social-container {
  justify-content: center;
  align-items: flex-start;
}
.social-card {
  text-decoration: none;
  color: inherit;
  text-align: center;
  margin: 10px 20px;
  max-width: 200px;
}

.social-card img.social-icon {
  width: 80px;
  height: auto;
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .brand-name {
    font-size: 16px;
  }
  .brand-slogan {
    font-size: 10px;
  }

  .width-800,
  .testimonial-card {
    width: 100%;
  }

  .about-container {
    max-width: 500px;
    width: 100%;
  }
  .section-title {
    font-size: 32px;
  }
  .section-head {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
  .color-text-side-inner p {
    font-size: 1.1em;
  }
  .social-container {
    flex-flow: column;
  }

  .social-card {
    margin: 20px auto;
  }
  .footer .site-brand,
  .footer .site-brand * {
    text-align: center !important;
    margin: auto;
  }
  .footer-menu {
    display: flex;
    width: auto;
    flex-flow: column;
  }
  .footer-menu li {
    margin: 10px 0;
  }

  .contact-container {
    flex-flow: wrap;
  }
}

@media screen and (max-width: 599px) {
  .brand-name {
    font-size: 16px;
  }
  .brand-slogan {
    display: none;
  }
  .footer .brand-slogan {
    font-size: 12px;
    display: block;
  }
  .width-800,
  .testimonial-card {
    width: 100%;
  }

  .about-container {
    width: 100%;
  }
  .section-title {
    font-size: 32px;
  }
  .section-head {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
  .color-text-side-inner p {
    font-size: 1.1em;
  }
  .social-container {
    flex-flow: column;
  }

  .social-card {
    margin: 20px auto;
  }
  .footer .site-brand,
  .footer .site-brand * {
    text-align: center !important;
    margin: auto;
  }
  .footer-menu {
    display: flex;
    width: auto;
    flex-flow: column;
  }
  .footer-menu li {
    margin: 10px 0;
  }

  .contact-container {
    flex-flow: wrap;
  }
}
